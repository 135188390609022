/**
 * @file   src/containers/profile/OtpVerification.tsx
 * @brief  Phone otp verification page
 * @date   June, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO *
 */
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import HomeLayout from '../../components/HomeLayout';
import { useAppSelector, useAppDispatch } from '../../hooks';
import { RootState } from '../../store';
import Loader from '../../components/Loader';
import OtpInput from 'react-otp-input';
import { MessageToaster } from '../../utils/ToastUtil';
import { verifyOtpCode, sendOtpToPhone, createTeacherProfile } from '../../store/actions/profileActions';
import { useLocation } from 'react-router-dom';
import { resetOptVerify } from '../../store/slices/profileSlice';
import { RoleTypeIds } from '../../utils/enums';
import { uploadFile, uploadFilePathUpdate } from '../../store/actions/organizationActions';
import { setItemLocalStorage, useIntlMessages, getFromLocalStorage } from '../../utils/helper';
import { IUploadFilePathUpdateParams } from '../../interfaces/OrganisationInterface';
import { resetProfileCreation } from '../../store/slices/profileSlice';
import { resetaddGoalStepOne } from '../../store/slices/organizationSlice';
import LinkMailTo from '../../components/MailTo';
import { MAIL_TO_ADDRESS } from '../../utils/constants';
import GuestFeedback from '../../components/GuestFeedback';

const Otpverify = () => {
  // Route navigation object creation
  const navigate = useNavigate();
  // Action dispatch object
  const dispatch = useAppDispatch();
  // Message toast object creation
  const toastObj = new MessageToaster();
  // location object
  const location = useLocation();
  // Accessing redux state variables
  const mailTo = 'mailto:' + MAIL_TO_ADDRESS;
  const {
    verifyOtpApiLoading,
    verifyOtpApiResponseMessage,
    verifyOtpApiSuccess,
    phoneOtpApiLoading,
    createProfileApiLoading,
    createProfileApiSuccess,
    createProfileApiResponseCode,
    profileForm,
    profileImgData,
  } = useAppSelector((state: RootState) => state.profile);
  const {
    uploadFileApiData,
    uploadFileApiLoading,
    uploadFileApiSuccess,
    uploadFileResponseCode,
    uploadFileResponseMessage,
    uploadFilePathUpdateApiLoading,
    uploadFilePathUpdateApiSuccess,
    uploadFilePathUpdateResponseMessage,
  } = useAppSelector((state: RootState) => state.organization);
  // Component state variables
  const [otp, setOtp] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(false);
  const [resendText, setResendText] = useState<string>('');
  const [showPopup, setShowPopup] = useState(false);
  const unexpectedErrorMessage = useIntlMessages('Something.Wentwrong.Error');
  let timer: any;
  let countdown = 60;
  const userData = getFromLocalStorage('MI_USR_DATA');
  // redirect to login page after otp verification
  useEffect(() => {
    if (verifyOtpApiSuccess) {
      toastObj.toastSuccess(verifyOtpApiResponseMessage);
      dispatch(createTeacherProfile(profileForm));
    } else if (!verifyOtpApiLoading && !verifyOtpApiSuccess && verifyOtpApiResponseMessage.length > 0) {
      toastObj.toastError(verifyOtpApiResponseMessage);
    }
  }, [verifyOtpApiLoading]);
  // reset verify otp
  useEffect(() => {
    return () => {
      dispatch(resetOptVerify());
    };
  }, []);
  // handle profile image or avatar image upload after profile adding
  useEffect(() => {
    if (createProfileApiSuccess) {
      const storageData = getFromLocalStorage('MI_USR_DATA');
      const userData = storageData ? storageData : {};
      userData.IsProfileCreated = true;
      setItemLocalStorage('MI_USR_DATA', userData);
      if (profileImgData.profileImage !== null && profileImgData.profileImageUrl.length > 0) {
        createFileUrlForImage();
      } else if (profileImgData.profileImage === null && profileImgData.avatarUrl.length > 0) {
        updateAssetPathForProfile();
      } else {
        navigate('/myprofile');
      }
    }
  }, [createProfileApiLoading]);

  useEffect(() => {
    if (uploadFilePathUpdateApiSuccess && createProfileApiSuccess) {
      // toastObj.toastSuccess(profileAddedMessage);
      if ([RoleTypeIds.ORGANIZATION_ADMIN, RoleTypeIds.ORGANIZATION_MANGER].includes(userData.RoleTypeId) && !userData.HasActivePlan) {
        navigate('/chooseplan');
      } else if (userData.RoleTypeId === RoleTypeIds.SYSTEM_ADMIN) {
        navigate('/home');
      } else {
        navigate('/vieworganization');
      }
    } else if (!uploadFilePathUpdateApiSuccess && !uploadFilePathUpdateApiLoading && uploadFilePathUpdateResponseMessage.length > 0) {
      toastObj.toastError(unexpectedErrorMessage);
    }
  }, [uploadFilePathUpdateApiLoading]);

  // Handle the upload path api response
  useEffect(() => {
    if (uploadFileResponseCode > 0 && uploadFileApiSuccess) {
      uploadPicToBucket();
    }
  }, [uploadFileResponseCode, uploadFileResponseMessage]);

  useEffect(() => {
    if (!createProfileApiSuccess && createProfileApiResponseCode > 0) {
      if ([RoleTypeIds.ORGANIZATION_ADMIN, RoleTypeIds.ORGANIZATION_MANGER].includes(userData.RoleTypeId) && !userData.HasActivePlan) {
        navigate('/chooseplan');
      } else if (userData.RoleTypeId === RoleTypeIds.SYSTEM_ADMIN) {
        navigate('/home');
      } else {
        navigate('/vieworganization');
      }
    }
  }, [createProfileApiLoading]);

  // reset profile create on component unmount
  useEffect(() => {
    return () => {
      dispatch(resetProfileCreation());
      dispatch(resetaddGoalStepOne());
    };
  }, []);

  const uploadPicToBucket = async () => {
    try {
      const uploadURL = uploadFileApiData;
      if (uploadURL) {
        // PUT request: upload file to S3
        const result = await fetch(uploadURL, {
          method: 'PUT',
          body: profileImgData.profileImage,
        });
        if (result.status == 200) {
          updateAssetPathForProfile();
        }
      }
    } catch (error) {
      toastObj.toastError(unexpectedErrorMessage);
    }
  };
  // create pre signed url for profile image
  const createFileUrlForImage = () => {
    try {
      const formData = new FormData();
      formData.append('UploadExtension', profileImgData.profileImage.type);
      formData.append('UploadObjectKey', profileImgData.profileImgName);
      formData.append('UploadType', 'profile');
      dispatch(uploadFile(formData));
    } catch (error) {
      toastObj.toastError(unexpectedErrorMessage);
    }
  };
  // add uploaded image url in profile
  const updateAssetPathForProfile = () => {
    try {
      const filePath = profileImgData.profileImage !== null ? profileImgData.profileImgName : profileImgData.avatarUrl;
      const reqParams: IUploadFilePathUpdateParams = {
        FilePath: filePath,
        Type: 'profile',
        TypeId: userData.UserId,
      };
      // Api request to update file info after bucket upload and save profile info
      dispatch(uploadFilePathUpdate(reqParams));
    } catch (error) {
      toastObj.toastError(unexpectedErrorMessage);
    }
  };
  function startResendTimer() {
    setDisabled(true);
    // Start the countdown
    timer = setInterval(updateTimer, 1000);
  }
  function updateTimer() {
    if (countdown > 0) {
      setResendText(`Resend in ${countdown} seconds`);
      countdown--;
    } else {
      // Enable the button when the countdown reaches zero
      setDisabled(false);
      setResendText('');
      // Reset countdown for the next attempt
      countdown = 60;
      // Stop the timer
      clearInterval(timer);
    }
  }
  // handle verify opt submit. call verify otp api
  const handleSubmit = () => {
    // dispatch(createTeacherProfile(profileForm));
    if (otp.length !== 6) {
      toastObj.toastError('Enter a valid OTP.');
    } else {
      // dispatch(createTeacherProfile(profileForm));
      dispatch(
        verifyOtpCode({
          Otp: otp,
        }),
      );
    }
  };
  // handle resend otp functionality
  const resendOtp = () => {
    startResendTimer();
    setOtp('');
    dispatch(
      sendOtpToPhone({
        CountryCode: location?.state?.countryCode,
        PhoneNumber: location?.state?.phoneNo,
      }),
    );
  };

  const handleShowPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };
  return (
    <HomeLayout>
      <GuestFeedback show={showPopup} onClose={handleClosePopup} />
      <div className="login-main">
        <h1 className="mb-5">
          Welcome {userData?.Firstname} {userData?.Lastname}
        </h1>
        <h2 className="m-0 mb-2">Verify your phone number</h2>
        <p className="mb-4">
          We have sent a verification code to {location?.state?.countryCode}
          {location?.state?.phoneNo.replace(/\d(?=\d{3})/g, '*')} Enter the code to verify your phone number.
        </p>
        <form name="loginform ">
          <div className="emial-verify">
            <OtpInput value={otp} onChange={setOtp} numInputs={6} renderSeparator={<span></span>} renderInput={(props) => <input {...props} />} shouldAutoFocus={true} />
          </div>
          <Button className="w-100 mb-4" variant="primary" onClick={handleSubmit}>
            Verify Phone Number
          </Button>
          <div className="d-flex justify-content-between">
            <div>
              {disabled && resendText}
              {!disabled && (
                <Link to="#" className="text-left ms-2" onClick={() => resendOtp()}>
                  {!disabled && 'Resend OTP'}
                </Link>
              )}
            </div>
            <div className="text-end">
              Can't Verify?
              {/* <LinkMailTo className="text-right ms-2" label="Contact Admin" mailTo={mailTo} />*/}
              <Link to="" className="text-right ms-2" onClick={() => handleShowPopup()}>
                Contact Admin
              </Link>
            </div>
          </div>
        </form>
      </div>
      {(verifyOtpApiLoading || phoneOtpApiLoading || uploadFileApiLoading || uploadFilePathUpdateApiLoading) && <Loader />}
    </HomeLayout>
  );
};
export default Otpverify;
