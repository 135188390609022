/**
 * @file   src\components\ViewAll.tsx
 * @brief  View all motivational resources.
 * @date   Jan, 2024
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import '../../assets/css/Resource.scss';
import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useIntlActionMessages, useIntlMessages, isAdminTeacherManager, getFromLocalStorage, getCurrentOrgDetails } from '../../utils/helper';
import { Button, Col, Row, Breadcrumb } from 'react-bootstrap';
import Search from '../../components/MASearch';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../../store';
import { useAppDispatch, useAppSelector } from '../../hooks';
import Select from '../../components/MASelect';
import { slide as Filter } from 'react-burger-menu';
import Pagination from '../../components/Pagination';
import Close from '../../assets/img/Close.svg';
import { getViewAllMotivationalResource } from '../../store/actions/resourceActions';
import { PAGE_SIZE_12, DEFAULT_PAGE_INDEX, BUCKET_URL } from '../../utils/constants';
import Default from '../../assets/img/resource_grey.png';
import Loader from '../../components/Loader';
import { getSubCategoryList } from '../../store/actions/organizationActions';
import { ISelectOptionsNumber, ISelectOptionsString } from '../../interfaces/GeneralInterface';
import { ResourcetypeID } from '../../utils/enums';
import { resetMotivationalViewAll } from '../../store/slices/resourceSlice';
// Declare default params
const defaultApiParams = {
  CategoryId: 0,
  Filter: [
    {
      OrganizationID: '',
      SubCategoryIDS: [0],
    },
  ],
  Page: DEFAULT_PAGE_INDEX,
  PageSize: PAGE_SIZE_12,
  PageSort: 'NEWEST',
  Search: '',
};

const ResourcesViewall = () => {
  // Navigate object creation.
  const navigate = useNavigate();
  // Location object
  const location = useLocation();
  // Create action dispatch object.
  const dispatch = useAppDispatch();
  // Initialize language variables.
  const FilterResourcesText = useIntlActionMessages('Button.FilterResources');
  const SubCategoryText = useIntlActionMessages('Label.SubCategory');
  const ClearFilterText = useIntlActionMessages('Button.ClearFilter');
  const ApplyFiltersText = useIntlActionMessages('Button.ApplyFilters');
  const NodataText = useIntlActionMessages('Label.Nodata');
  const SelectText = useIntlActionMessages('PH.Select');
  // Access redux state variables.
  const { motivationalViewAllData, motivationalViewAllApiLoading, motivationalViewAllApiSuccess } = useAppSelector((state: RootState) => state.resource);
  const { getSubCategoryListAPiData, getSubCategoryListApiLoading } = useAppSelector((state: RootState) => state.organization);
  // Declare constants
  const generalFilter = [
    { label: 'Newest', value: 'NEWEST' },
    { label: 'Most Used', value: 'MOST_USED' },
    { label: 'Completion Rate', value: 'COMPLETION_RATE' },
  ];
  // Initialize component state variables.
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_INDEX);
  const [searchText, setSearchText] = useState<string>('');
  const [subCategoryList, setSubCategoryList] = useState<ISelectOptionsNumber[]>([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState<ISelectOptionsString[]>([]);
  const [apiParams, setApiParams] = useState<any>(defaultApiParams);
  const [generalFilterValue, setGeneralFilterValue] = useState<any>(generalFilter[0]);
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  // Set Api Params
  useEffect(() => {
    dispatch(resetMotivationalViewAll());
    const titleId = location?.state?.titleId ?? null;
    const CategoryId = titleId !== null ? titleId : defaultApiParams.CategoryId;
    let OrganizationID: string;
    if (isAdminTeacherManager()) {
      const userData = getFromLocalStorage('MI_USR_DATA');
      OrganizationID = getCurrentOrgDetails() !== null ? getCurrentOrgDetails().OrganizationID : userData.OrganizationId;
    } else {
      const OrganizationIDArray = location.state?.OrganizationID ?? null;
      OrganizationID = OrganizationIDArray?.[0]?.value?.toString();
    }
    if (CategoryId && OrganizationID) {
      const updatedApiParams = {
        ...defaultApiParams,
        CategoryId: CategoryId,
        Filter: [
          {
            OrganizationID: OrganizationID,
            SubCategoryIDS: [],
          },
        ],
      };
      setApiParams(updatedApiParams);
    }
    if (titleId !== null) {
      dispatch(getSubCategoryList({ CategoryId: CategoryId, OrganizationId: OrganizationID }));
    }
  }, [location?.state?.titleId]);
  // Api call to get motivational list
  useEffect(() => {
    // if (apiParams.Filter[0].OrganizationID) {
    dispatch(getViewAllMotivationalResource(apiParams));
    // }
  }, [apiParams]);
  // Set API params on filter change.
  useEffect(() => {
    setApiParams((apiParams: any) => ({
      ...apiParams,
      Page: currentPage,
    }));
  }, [currentPage]);
  // Get Subcategory details by organization
  useEffect(() => {
    if (getSubCategoryListAPiData && getSubCategoryListAPiData?.length > 0) {
      const subCategoryData: ISelectOptionsNumber[] = [];
      getSubCategoryListAPiData.forEach((item: any) => {
        item.Subcategory.forEach((subCategory: any) => {
          subCategoryData.push({
            label: subCategory.classname,
            value: +subCategory.classid,
          });
        });
      });
      setSubCategoryList(subCategoryData);
    } else {
      // If ResponseData is null or Subcategory is empty
      setSubCategoryList([]);
    }
  }, [getSubCategoryListApiLoading]);
  let debounceTimer: any;
  // Debounce function
  const debounce = (callback: any, delay: any) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(callback, delay);
  };
  // Search text change
  const handleSearchChange = (event: any) => {
    const searchValue = event.target.value;
    setSearchText(searchValue);
    debounce(() => {
      setApiParams((apiParams: any) => ({
        ...apiParams,
        Search: searchValue,
        Page: DEFAULT_PAGE_INDEX,
      }));
      setCurrentPage(DEFAULT_PAGE_INDEX);
    }, 500); // Debounce for 500 milliseconds
  };
  // Handle search button click
  const handleSearchClick = () => {
    setApiParams({ ...apiParams, Search: searchText });
  };
  // Handle Search Field input key down.
  const handleSearchKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setApiParams((apiParams: any) => ({
        ...apiParams,
        Search: searchText,
        Page: DEFAULT_PAGE_INDEX,
      }));
      setCurrentPage(DEFAULT_PAGE_INDEX);
    } else {
      setSearchText(event.target.value);
    }
  };
  // Handle general filter change
  const handleGeneralFilterChange = (event: any) => {
    setGeneralFilterValue([event]);
    setApiParams({
      ...apiParams,
      PageSort: event.value,
    });
  };
  // Handle subcategory change
  const handleSubCategoryChange = (event: any) => {
    setSelectedSubCategory(event);
  };
  // Handle reset filter
  const handleFilterReset = () => {
    setIsFilterApplied(false);
    setApiParams((apiParams: any) => ({
      ...apiParams,
      Filter: [
        {
          OrganizationID: '',
          SubCategoryIDS: [0],
        },
      ],
    }));
    setSelectedSubCategory([]);
    setOpen(false);
  };
  // Handle apply filter
  const handleFilterChange = () => {
    let OrganizationID: string;
    if (isAdminTeacherManager()) {
      const userData = getFromLocalStorage('MI_USR_DATA');
      OrganizationID = getCurrentOrgDetails()?.OrganizationID || userData?.OrganizationId;
    } else {
      const OrganizationIDArray = location.state?.OrganizationID ?? null;
      OrganizationID = OrganizationIDArray?.[0]?.value?.toString();
    }
    if (Array.isArray(selectedSubCategory)) {
      const subCategoryIds = selectedSubCategory.map((item: ISelectOptionsNumber) => item.value);
      setApiParams((apiParams: any) => ({
        ...apiParams,
        Filter: [
          {
            OrganizationID: OrganizationID,
            SubCategoryIDS: subCategoryIds,
          },
        ],
      }));
    }
    setIsFilterApplied(true);
    setOpen(false);
  };

  return (
    <>
      <div className="resources-wrap">
        <div className="page-title orgAdd-page-title d-flex justify-content-between align-items-center">
          <h3>
            {location?.state?.title} <FormattedMessage id="Hd.Resources" />
          </h3>
          <Col md="auto">
            <Breadcrumb className="breadcrumb">
              {location?.state?.tabId == 0 && (
                <Breadcrumb.Item onClick={() => navigate('/resources', { state: { tabId: 0 } })}>
                  <FormattedMessage id="Hd.MotivationalResources" />
                </Breadcrumb.Item>
              )}
              <Breadcrumb.Item active>
                <FormattedMessage id="label.viewall" />
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </div>
        <div className="content-sub mb-3 p-3">
          <Row className="d-flex justify-content-between">
            <Col md={4} className="form-sm">
              <Search
                placeholder={useIntlActionMessages('Label.SearchResource')}
                size="sm"
                onChange={handleSearchChange}
                onKeyDown={handleSearchKeyDown}
                onClick={handleSearchClick}
                value={searchText}
              />
            </Col>
            <Col className="form-sm d-flex" lg={3}>
              {location?.state?.titleId !== null && (
                <Filter
                  isOpen={open}
                  onOpen={() => {
                    setOpen(true);
                  }}
                  onClose={() => setOpen(false)}
                  width={450}
                  right
                  pageWrapId={'filter-wrapper'}
                  burgerButtonClassName={isFilterApplied ? 'active' : ''}
                  outerContainerId={'outer-container'}
                  customCrossIcon={<img src={Close} />}
                >
                  <div id="filter-wrapper" className="filter-main ">
                    <h4 className="mb-5">{FilterResourcesText}</h4>
                    <Col className="form-sm">
                      <Select
                        label={SubCategoryText}
                        options={subCategoryList}
                        value={selectedSubCategory}
                        placeholder={SelectText}
                        onChange={(e: any) => handleSubCategoryChange(e)}
                        isMulti
                      />
                    </Col>
                    <Row className="mt-4">
                      <Col>
                        <Button variant="outline-primary" className="w-100" onClick={handleFilterReset}>
                          {ClearFilterText}
                        </Button>
                      </Col>
                      <Col>
                        <Button variant="primary" className="w-100" onClick={handleFilterChange}>
                          {ApplyFiltersText}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Filter>
              )}
              <Col className="form-sm">
                <Select
                  className="select-res-rep"
                  options={generalFilter}
                  placeholder={useIntlMessages('PH.Newest')}
                  onChange={(e: any) => handleGeneralFilterChange(e)}
                  value={generalFilterValue}
                />
              </Col>
            </Col>
          </Row>
        </div>
        <div className="content-sub content-sub-thumb mb-3 p-3">
          <Row className="mb-3">
            {motivationalViewAllApiSuccess && motivationalViewAllData?.Resources?.length > 0 ? (
              motivationalViewAllData?.Resources?.map((resources: any) => (
                <Col md={3} className="mb-3">
                  <div className="thum-box">
                    <img
                      src={resources && resources.File ? BUCKET_URL + resources.File : Default}
                      alt={resources.FileName}
                      onClick={() =>
                        navigate(`/resourceDetails/${ResourcetypeID.MOTIVATIONAL_RESOURCE}/${resources.Key}`, {
                          state: {
                            tabId: location?.state?.tabId,
                          },
                        })
                      }
                    />
                    <div className="slid-name">
                      <p>{resources?.FileName}</p>
                    </div>
                  </div>
                </Col>
              ))
            ) : (
              <div className="content-sub content-area-padding border-top text-center">
                <h6>{NodataText}</h6>
              </div>
            )}
          </Row>
          {motivationalViewAllApiSuccess && motivationalViewAllData?.Resources?.length > 0 && motivationalViewAllData.TotalCount > PAGE_SIZE_12 && (
            <Pagination
              pageSize={PAGE_SIZE_12}
              totalitems={motivationalViewAllData.TotalCount}
              pageLimit={PAGE_SIZE_12}
              setCurrentPage={(page: number) => setCurrentPage(page)}
              currentPage={currentPage - 1}
              prevPage={-1}
              itemsDisplayed={motivationalViewAllData?.Resources?.length}
            />
          )}
        </div>
      </div>
      {motivationalViewAllApiLoading && <Loader />}
    </>
  );
};
export default ResourcesViewall;
