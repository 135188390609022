/**
 * @file   src\validations\organisationSchema.ts
 * @brief  This file is responsible for defining organisation validation schemas.
 * @date   Dec, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import * as yup from 'yup';
import { useIntlActionMessages } from '../utils/helper';
import { EMAIL_REGEX } from '../utils/constants';

// Feedback Form Schema
export const FEEDBACK_SCHEMA = yup.object({
  ContactAdminSubject: yup
    .string()
    .trim()
    .min(3, useIntlActionMessages('Form.OrganisationName.Min'))
    .max(100, useIntlActionMessages('Form.OrganisationName.Max'))
    .required(useIntlActionMessages('Form.Subject.Required')),
  ContactAdminMessage: yup
    .string()
    .max(1000, useIntlActionMessages('Form.Address.Max'))
    .min(5, useIntlActionMessages('Form.Address.Min'))
    .required(useIntlActionMessages('Form.Content.Required')),
});

// Feedback Form Schema
export const GUEST_FEEDBACK_SCHEMA = yup.object({
  EmailID: yup
    .string()
    .trim()
    .matches(EMAIL_REGEX, useIntlActionMessages('Form.Email.Valid'))
    .max(250, useIntlActionMessages('Form.Email.MaxLength'))
    .required(useIntlActionMessages('Form.Contact.Email.Required')),
  ContactAdminSubject: yup
    .string()
    .trim()
    .min(3, useIntlActionMessages('Form.OrganisationName.Min'))
    .max(100, useIntlActionMessages('Form.OrganisationName.Max'))
    .required(useIntlActionMessages('Form.Subject.Required')),
  ContactAdminMessage: yup
    .string()
    .max(1000, useIntlActionMessages('Form.Address.Max'))
    .min(5, useIntlActionMessages('Form.Address.Min'))
    .required(useIntlActionMessages('Form.Content.Required')),
});
